<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <CRow>
      <CCard color="white" text-color="dark" class="text-center col-md-5">
      <CCardBody>
        <h4 class="font-weight-normal text-left">{{ title }}</h4>
        <p class="text-left text-description">{{ description }}</p>
        <hr />
        <p class="text-left font-weight-normal text-grey">
          <em class="fa fa-calendar-check-o"></em> วันที่รับคูปอง :
          {{ createdAt }}
        </p>
        <CCard>
          <CCardBody color="light">
            <h5 class="text-dark">
              รหัสแลกรับสินค้า : {{ data.redemptionCode }}
            </h5>
            <vue-qrcode
              class="img-fluid mt-3 mb-3"
              :value="qrcodeData"
              style="width:300px"
            />
            <p style="font-size:12px"></p>
          </CCardBody>
        </CCard>

        <hr />
        <div v-if="isUsed === false">
          <p class="text-center text-description">
            กรุณาแสดงคูปองนี้ให้แก่ร้านค้าเพื่อใช้สิทธิ์
          </p>
          <CButton color="success" block @click="popupModal = true" size="lg">
            ใช้คูปอง
          </CButton>
        </div>
        <div v-else-if="isUsed === true">
          <CButton color="danger" block disabled size="lg">
            คูปองถูกใช้งานแล้ว
          </CButton>
        </div>
      </CCardBody>
    </CCard>
    </CRow>
    <CRow>
      <CModal
        :show.sync="popupModal"
        footer=""
        centered
        title="ใช้คูปอง">
      <h5 class="text-center">คุณต้องการใช้งานคูปองใช่หรือไม่?</h5>
        <p class="text-center text-description" style="font-size:14px">
           เมื่อกดใช้คูปองแล้ว จะไม่สามารถใช้คูปองนี้ได้อีก
        </p>
        <template #footer-wrapper>
          <CRow class="justify-content-center">
            <CCol col="5" class="ml-1">
              <CButton
                block
                color="success"
                v-on:click="useRewardCardCoupon">
                กดใช้คูปอง
              </CButton>
            </CCol>
            <CCol col="5" class="mr-1">
              <CButton block color="danger" @click="popupModal = false">
                ยังไม่ใช้
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </CContainer>
</template>

<script>
import crm from '@/services/crm'
import liff from '@line/liff'
import moment from 'moment'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      data: {},
      member: {},
      uid: '',
      isUsed: '',
      rewardcard: '',
      title: '',
      yourpoint: 0,
      description: '',
      qrcodeData: '',
      popupModal: false,
    }
  },
  computed: {
    objectId() {
      return this.$route.params.objectId
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    expireAt() {
      return moment(this.data.expiredAt).format('DD/MM/YYYY')
    },
  },
  created() {
    this.getRedemption()
  },
  methods: {
    getRedemption() {
      let objectId = this.objectId

      crm
        .get('/api/v1.0/rewardcard/getredemptionbyobjectId/' + objectId)
        .then((res) => {
          if (res.data.data.documents !== null) {
            this.data = res.data.data.documents
            this.rewardcard = this.data.RewardCard

            let unitName = this.rewardcard.unit.name
            let free = this.rewardcard.free
            let point = this.rewardcard.point

            this.title = 'แลกรับสินค้าฟรี ' + free + ' ' + unitName
            this.description = 'สะสมครบ ' + point + ' ' + unitName

            this.uid = this.data.uid
            this.isUsed = this.data.isUsed
            this.qrcodeData = objectId + '|' + this.data.redemptionCode

            document.title = this.title
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    useRewardCardCoupon() {
      let objectId = this.objectId

      crm
        .post('/api/v1.0/userewardcardcoupon/' + objectId)
        .then((res) => {
          // alert('คูปองถูกใช้งาน')
          // liff.closeWindow()
          this.popupModal = false
          this.isUsed = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
